import React from "react";
import { Container } from "react-bootstrap";
import { Row } from "antd";

const ContactUs = () => {
  return (
    <div style={{ minHeight: "100vh" }}>
      <Container>
        <Row justify="center">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <br />
            <br />
            <p style={{ textAlign: "center" }}>
              Thank you for visiting Aditya Fitness! If you have any questions,
              concerns, or feedback regarding our programs, services, or
              policies, please don't hesitate to contact us using the
              information provided below:
              <br />
              <br />
              Email:{" "}
              <a href="mailto:contact@adityafitness.com">
                contact@adityafitness.com
              </a>{" "}
            </p>
            <h6 style={{ marginTop: 150 }}>
              We value your feedback and are here to assist you in any way we
              can.
            </h6>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default ContactUs;
