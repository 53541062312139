// AuthRoute.jsx
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { authSetToCart } from "./actions/authcart";
import { SET_USER_DATA } from "./actions/common";
import { axiosAuth } from "./api";
import { API_ENDPOINTS } from "./api/apiEndpoints";
import Loader from "./common/components/Loader";

export const isUserDetailsUpdate = (oldData, newData) => {
  const oldCoupon = JSON.stringify(oldData.coupon);
  const newCoupon = JSON.stringify(newData.coupon);
  const oldPurchasedCourses = JSON.stringify(oldData.purchasedCourses);
  const newPurchasedCourses = JSON.stringify(newData.purchasedCourses);
  const oldRatings = JSON.stringify(oldData.ratings ?? []);
  const newRatings = JSON.stringify(newData.ratings ?? []);

  if (
    oldData?.active === newData?.active &&
    oldData?.email === newData?.email &&
    oldData?.name === newData?.name &&
    oldData?.profileImg === newData?.profileImg &&
    oldData?.userType === newData?.userType &&
    oldData?.isBanned === newData?.isBanned &&
    oldData?._id === newData?._id &&
    oldCoupon === newCoupon &&
    oldPurchasedCourses === newPurchasedCourses &&
    oldRatings === newRatings &&
    oldData?.createdAt === newData?.createdAt
  ) {
    return true;
  }
  return false;
};

const AuthRoute = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    const userData = localStorage.getItem("user");
    const token = localStorage.getItem("authToken") || "";

    // For setting the updated user data
    if (userData) {
      const user = JSON.parse(userData);
      dispatch({
        type: SET_USER_DATA,
        payload: {
          user: userData
            ? {
                ...user,
              }
            : null,
          token,
        },
      });

      axiosAuth.get(API_ENDPOINTS.GET_USER_PROFILE).then((res) => {
        if (res?.data?.data?._id) {
          setIsAuthenticated(true);
          const result = isUserDetailsUpdate(user, res.data.data);
          if (!result) {
            localStorage.setItem("user", JSON.stringify(res.data.data));
            dispatch({
              type: SET_USER_DATA,
              payload: { user: res?.data?.data ?? null },
            });
          }
        } else {
          setIsAuthenticated(false);
          localStorage.removeItem("user");
          localStorage.removeItem("authToken");
        }
      });
    }

    axiosAuth.get(API_ENDPOINTS.GET_CART).then((res) => {
      if (res?.data?.data?.length) {
        dispatch(authSetToCart(res?.data?.data[0]?.courses ?? []));
      }
    });
  }, []);

  if (isAuthenticated === null) {
    return <Loader />; // Show loader while checking authentication
  }

  return isAuthenticated ? children : <Navigate to="/login" />;
};

export default AuthRoute;
