import React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import HeadingUnderline from "../../common/components/HeadingUnderline";

const Spacer = styled.div`
  height: 20px;
`;

const RefundPolicy = () => (
  <>
    <Container style={{ height: "70vh" }}>
      <Spacer />
      <HeadingUnderline title="Refund policy" />
      <Spacer />
      <p>
        Aditya Fitness does not offer refunds for any purchases or services
        rendered. All sales are final. We strive to provide accurate and
        valuable information through our hormone optimization programs, but
        results may vary depending on individual circumstances and commitment to
        the program. Please consider this policy before making a purchase.
      </p>
      <Spacer />
    </Container>
  </>
);

export default RefundPolicy;
