import styled from "styled-components";
import { isMobileOnly, isMobile, isTablet } from "react-device-detect";
import { theme } from "../../utils/theme";

export const Spacer = styled.div`
  height: 50px;
`;

export const ColoredContainer = styled.div`
  background-image: linear-gradient(
    to right,
    ${theme.backgrounds.main},
    #1a1a1a
  );
  min-height: 100vh;
  padding: ${isMobile ? "15px 0 30px 0px" : "0 0 30px 0"};
`;

export const CapitalizedText = styled.div`
  text-transform: capitalize;
  &:focus {
    outline: 0;
  }
`;

export const NavbarItem = styled.div`
  font-size: 16px;
  position: relative;
  display: inline-block;
  display: flex;
  justify-content: flex-start;
  color: ${({ theme, isButton }) =>
    isButton ? theme.colors.black : theme.colors.lightgrey};
  overflow: hidden;
  font-weight: 500;
  font-weight: ${({ isBold }) => isBold && "bold !important"};
  background: ${({ theme, isButton }) =>
    !isButton
      ? `linear-gradient(to right, ${theme.backgrounds.main}, ${
          theme.backgrounds.main
        } 50%, ${isButton ? theme.colors.black : theme.colors.lightgrey} 50%)`
      : ""};
  background-clip: ${({ isButton }) => !isButton && "text"};
  -webkit-background-clip: ${({ isButton }) => !isButton && "text"};
  -webkit-text-fill-color: ${({ isButton }) => !isButton && "transparent"};
  background-size: ${({ isButton }) => !isButton && "200% 100%"};
  background-position: ${({ isButton }) => !isButton && "100%"};
  transition: ${({ isButton }) =>
    !isButton && "background-position 275ms ease"};
  text-decoration: none;
  margin-right: 15px;
  border: ${({ isButton }) => isButton && "1px solid black"};
  border-radius: ${({ isButton }) => isButton && "6px"};
  padding: ${({ isButton }) => isButton && "0 20px"};
  line-height: ${({ isButton }) => isButton && "30px"};
  &:hover {
    cursor: pointer;
    background-position: 0 100%;
  }
  &:focus {
    outline: 0;
  }
  svg {
    &:hover {
      color: ${({ theme }) => theme.backgrounds.main};
    }
  }
`;

export const SignUpNavItem = styled(NavbarItem)`
  background: ${({ theme }) => theme.backgrounds.darkorchid};
  margin-right: 0;
  border: ${({ isButton, theme }) =>
    isButton && `1px solid ${theme.backgrounds.darkorchid}`};
  color: ${({ theme }) => theme.colors.white};
  line-height: 50px;
  font-size: 25px;
  @media (max-width: 767px) {
    line-height: 35px !important;
    font-size: 16px !important;
    margin: 0 auto !important;
  }
`;

export const FooterContainer = styled.div`
  display: flex;
  flex: 1;
  background-color: ${({ theme }) => theme.backgrounds.black};
`;

export const MultiPurposeText = styled.p`
  margin: 5px 0px;
  font-size: 14px;
  &:hover {
    cursor: ${({ hoverOnParent }) => hoverOnParent && "pointer"};
  }
  span {
    color: ${({ theme }) => theme.colors.main};
    font-weight: 500;
    &:hover {
      cursor: pointer;
    }
  }
`;

export const AuthActionsContainer = styled.div`
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  align-items: flex-start;
  ${MultiPurposeText} {
    margin: 0;
  }
`;

export const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 280px);
  margin-top: 60px;
`;

export const LoginFormWrapper = styled.div`
  width: 350px;
  border: ${({ theme }) => `1px solid ${theme.colors.main}`};
  padding: 20px;
  border-radius: 6px;
`;
