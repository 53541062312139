import React, { useState, useRef, useEffect, useCallback } from "react";
import { Button, Dropdown, Menu, Tag } from "antd";
import { isMobile, isMobileOnly, isTablet } from "react-device-detect";
import { Container, Nav, Navbar } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ShoppingCartOutlined, BellOutlined } from "@ant-design/icons";
import styled from "styled-components";
// import { logout, setModalType } from "../../actions/common";
import logo from "../../../assets/logo.svg";
import {
  CapitalizedText,
  NavbarItem,
  SignUpNavItem,
} from "../../../common/components";
// import { logoutUserHandler } from "../../utils/helper";
// import BuyCourseButton from "../BuyCourseButton";
// import { setNotificationCount } from "../../actions/notification";
import { axiosAuth } from "../../../api";
import { API_ENDPOINTS } from "../../../api/apiEndpoints";

const CartCourseCount = styled.div`
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  top: -10px;
  left: 12px;
  background: #f9ae00;
  border-radius: 50%;
  min-width: 22px;
  min-height: 22px;
`;

const CartContainer = styled.div`
  position: relative;
  &:hover {
    cursor: pointer;
    svg {
      color: #f9ae00;
    }
  }
`;

const NotificationContainer = styled(CartContainer)`
  position: absolute;
  right: ${isTablet ? "60px" : "45px"};
  top: 15px;
  display: flex;
  align-items: center;
  height: 55%;
`;

const Header = ({ logoutUser }) => {
  const [sticky, setSticky] = useState({ isSticky: false, offset: 0 });
  const cartData = useSelector((state) => state.cart.cart);
  const authcartData = useSelector((state) => state.authcart.cart);
  const tokenIsExist = useSelector((state) => state.common.authToken);
  const count = useSelector((state) => state.notification.count);
  const user = useSelector((state) => state.common.user);
  const headerRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const handleLinkClick = useCallback((path) => {
    navigate(path);
  }, []);

  const handleScroll = (elTopOffset, elHeight) => {
    if (window.pageYOffset > elTopOffset + elHeight) {
      if (location.pathname === "/") {
        setSticky({ isSticky: true, offset: elHeight });
        document.body.classList.add("sticky-header-enabled");
      } else {
        setSticky({ isSticky: false, offset: 0 });
        document.body.classList.remove("sticky-header-enabled");
      }
    } else {
      setSticky({ isSticky: false, offset: 0 });
      document.body.classList.remove("sticky-header-enabled");
    }
  };

  useEffect(() => {
    const header = headerRef.current.getBoundingClientRect();
    const handleScrollEvent = () => {
      handleScroll(header.top, header.height);
    };

    window.addEventListener("scroll", handleScrollEvent);

    if (location.pathname !== "/notifications") {
      const endpoint = `${API_ENDPOINTS.GET_ALL_NOTIFICATION}?offset=0&limit=50`;
      axiosAuth
        .get(endpoint)
        .then((res) => {
          if (res?.data?.data && res?.data?.status) {
            const allUnreads = res?.data?.data?.filter((x) => x.read === false);
            if (res?.data?.data.length > 0) {
              //   dispatch(setNotificationCount(allUnreads.length));
            }
          }
        })
        .catch(() => {});
    }

    return () => {
      window.removeEventListener("scroll", handleScrollEvent);
    };
  }, [location]);

  const handleLogoutClick = async () => {
    // logoutUserHandler(logoutUser, navigate);
  };

  const menu = (
    <Menu className="menu-key-list">
      <Menu.Item className="menu-key" key="0" style={{ textAlign: "center" }}>
        <CapitalizedText>
          <strong>{user?.name || ""}</strong>
        </CapitalizedText>
      </Menu.Item>
      <Menu.Divider />
      {user?.userType === "admin" && (
        <Menu.Item
          onClick={() => handleLinkClick("/admin-dashboard")}
          className="menu-key"
          key="1"
        >
          Dashboard
        </Menu.Item>
      )}
      {user?.isLiveUser && (
        <Menu.Item
          onClick={() => handleLinkClick("/live-lectures")}
          className="menu-key"
          key="10"
        >
          Live Lectures
        </Menu.Item>
      )}
      <Menu.Item
        onClick={() => handleLinkClick("/profile")}
        className="menu-key"
        key="1"
      >
        My Profile
      </Menu.Item>
      <Menu.Item
        className="menu-key"
        // onClick={handleLogoutClick}
        key="3"
        style={{ textAlign: "center" }}
      >
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <Navbar
      expand="sm"
      bg="light"
      variant="light"
      className={`navbar${sticky.isSticky ? " sticky" : ""}`}
      style={{ padding: 0 }}
      ref={headerRef}
    >
      <Container>
        {!isMobileOnly ? (
          <Navbar.Brand onClick={() => handleLinkClick("/")}>
            <img
              alt=""
              src={logo}
              width="120"
              height="80px"
              className="d-inline-block align-top brand-image"
            />
          </Navbar.Brand>
        ) : (
          <img
            src={logo}
            width="70px"
            className="d-inline-block align-top brand-image-mobile"
            onClick={() => handleLinkClick("/")}
            alt="logo"
          />
        )}

        {tokenIsExist && isMobile && (
          <NotificationContainer
            onClick={() => handleLinkClick("/notifications")}
          >
            {count > 0 ? (
              <Tag
                style={{
                  position: "absolute",
                  top: "-10px",
                  right: 0,
                  borderRadius: "50%",
                }}
                color="#cf1322"
              >
                {count > 9 ? `${count}+` : count}
              </Tag>
            ) : null}
            <BellOutlined
              style={{
                fontSize: "24px",
                color: "#979197",
                marginRight: "15px",
              }}
            />
          </NotificationContainer>
        )}

        {!isMobile && (
          <>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav
                className="ml-auto"
                style={{ width: "100%", height: "1px" }}
              />
              <Nav className="nav-list-custom">
                <NavbarItem onClick={() => handleLinkClick("/")}>
                  {!tokenIsExist ? "Home" : "Courses"}
                </NavbarItem>
                {tokenIsExist && (
                  <CartContainer
                    onClick={() =>
                      handleLinkClick(tokenIsExist ? "/auth-cart" : "/cart")
                    }
                  >
                    <ShoppingCartOutlined
                      style={{
                        fontSize: "24px",
                        color: "#979197",
                        marginRight: "15px",
                      }}
                    />
                    {tokenIsExist
                      ? authcartData?.length > 0 && (
                          <CartCourseCount>
                            {authcartData?.length}
                          </CartCourseCount>
                        )
                      : cartData?.length > 0 && (
                          // <CartCourseCount>{cartData?.length}</CartCourseCount>
                          <></>
                        )}
                  </CartContainer>
                )}
                {tokenIsExist && (
                  <CartContainer
                    onClick={() => handleLinkClick("/notifications")}
                  >
                    {count > 0 ? (
                      <Tag
                        style={{
                          position: "absolute",
                          top: "-10px",
                          right: 0,
                          borderRadius: "50%",
                        }}
                        color="#cf1322"
                      >
                        {count > 9 ? `${count}+` : count}
                      </Tag>
                    ) : null}
                    <BellOutlined
                      style={{
                        fontSize: "24px",
                        color: "#979197",
                        marginRight: "15px",
                      }}
                    />
                  </CartContainer>
                )}
                {tokenIsExist ? (
                  <Dropdown overlay={menu} trigger={["click"]}>
                    {user?.profileImg ? (
                      <img
                        className="profile-image"
                        style={{
                          height: "60px",
                          width: "60px",
                          borderRadius: "40px",
                        }}
                        src={user?.profileImg}
                        alt="profile"
                      />
                    ) : (
                      <div
                        className="initials"
                        role="button"
                        onClick={(e) => e.preventDefault()}
                      >
                        <span>{user.name.toUpperCase().charAt(0)}</span>
                      </div>
                    )}
                  </Dropdown>
                ) : (
                  <>
                    <Button
                      className="signUpBtn"
                      onClick={() => handleLinkClick("/login")}
                    >
                      Login
                    </Button>
                  </>
                )}
              </Nav>
            </Navbar.Collapse>
          </>
        )}
      </Container>
    </Navbar>
  );
};

// const mapDispatchToProps = (dispatch) => ({
//   setModalType: (type) => dispatch(setModalType(type)),
//   logoutUser: () => dispatch(logout()),
// });

export default Header;
