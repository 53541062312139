import React from "react";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import HeadingUnderline from "../../common/components/HeadingUnderline";

const Spacer = styled.div`
  height: 20px;
`;

const Disclaimer = () => (
  <div style={{ minHeight: "100vh" }}>
    <Helmet>
      <title>Disclaimer</title>
      <meta
        name="description"
        content="Hormone optimization,
        Reproductive health,
        Testosterone,
        Hormonal balance,
        Endocrine disruptors,
        Fitness,
        Fertility,
        Environmental toxins,
        Wellness,
        Workout plan,
        Diet Plan,
        Flexible Diet,
        Muscle Building, 
        Fat Loss"
      />
    </Helmet>
    <Container>
      <Spacer />
      <HeadingUnderline title="Disclaimer" />
      <p className="text-center">
        Welcome to Aditya Fitness! Our mission is to provide information and
        programs aimed at hormone optimization by eliminating Endocrine
        Disrupting Chemicals (EDCs) from your life. While we strive to offer
        accurate and up-to-date information, the content on this website is for
        informational purposes only and should not be considered medical advice.
        Please consult with a qualified healthcare professional before making
        any changes to your lifestyle or health regimen. Aditya Fitness is not
        liable for any direct or indirect damages or consequences resulting from
        the use or misuse of information presented on this website.
      </p>
      <Spacer />
    </Container>
  </div>
);

export default Disclaimer;
