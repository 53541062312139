import React from "react";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";

const ActionButton = ({ smallButton }) => {
  const history = useNavigate();
  const handleClick = () => history("/webinar-registration");

  if (smallButton) {
    return (
      <button className="gradient-btn" onClick={handleClick}>
        Let's Talk
      </button>
    );
  }

  return (
    <button
      onClick={handleClick}
      type="button"
      className={`button-85 ${isMobile ? "button-85-mobile" : ""}`}
    >
      Let's Talk
      {/* <span style={{ textDecoration: "line-through" }}>2999</span>
      &nbsp; &#8377;499/- */}
    </button>
  );
};

export default ActionButton;
