import React from "react";
import styled from "styled-components";
import { theme } from "../../../utils/theme";

const SubjectCard = ({ icon, title, description }) => {
  return (
    <Card>
      <IconWrapper>
        <Icon src={icon} alt={title} />
      </IconWrapper>
      <Title>{title}</Title>
      <Line />
      <Description>{description}</Description>
    </Card>
  );
};

export default SubjectCard;

// Styled Components for the SubjectCard
const Line = styled.div`
  height: 5px;
  width: 100%;
  background: ${theme.backgrounds.primary};
  margin: 5px 0 10px 0;
`;

const Card = styled.div`
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  margin: 10px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  &:hover {
    transform: translateY(-5px);
  }
`;

const IconWrapper = styled.div`
  margin-bottom: 10px;
`;

const Icon = styled.img`
  width: 40px;
  height: 40px;
`;

const Title = styled.h3`
  font-size: 1.2em;
  margin: 10px 0;
  min-height: 40px;
`;

const Description = styled.p`
  color: #555;
  font-size: 0.9em;
`;
