import React from "react";
import styled from "styled-components";
import SubjectCard from "./SubjectCard";
import logo from "../../../assets/logo.svg";
import { Container } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { Heading } from "../AboutMe";
import ActionButton from "../ActionButton";

// Sample Data for subjects
const subjects = [
  {
    icon: logo,
    title: "Low Testosterone & Infertility",
    description: "Of course, we can’t do data visualisation without data...",
  },
  {
    icon: logo,
    title: "Developmental Issues",
    description: "We want to have visualisations that make sense...",
  },
  {
    icon: logo,
    title: "Metabolic & Thyroid Function Impairment",
    description: "We want to have visualisations that make sense...",
  },
  {
    icon: logo,
    title: "Decreased Libido & ED",
    description: "We want to have visualisations that make sense...",
  },
  {
    icon: logo,
    title: "Increased Body Fat & Lack of Muscle",
    description: "We want to have visualisations that make sense...",
  },
  {
    icon: logo,
    title: "Lack of Energy & Motivation",
    description: "We want to have visualisations that make sense...",
  },
];

const Subjects = () => {
  return (
    <ColoredContainer>
      <Container>
        <CustomHeading>
          Do you know there are thousands of chemicals you’re consuming daily
          that are linked to the following side effects?
        </CustomHeading>
        <GridLayout>
          {subjects.map((subject, index) => (
            <SubjectCard
              key={index}
              icon={subject.icon}
              title={subject.title}
              description={subject.description}
            />
          ))}
        </GridLayout>
        <ActionContainer>
          <ActionButton smallButton />
        </ActionContainer>
      </Container>
    </ColoredContainer>
  );
};

export default Subjects;

const ActionContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const CustomHeading = styled(Heading)`
  text-align: center;
`;

const ColoredContainer = styled.div`
  background-color: #E1FAEB;
  padding: ${isMobile ? "15px 0 30px 0px" : "30px 0 30px 0"};
`;

// Styled Component for the Course Layout (Grid)
const GridLayout = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 20px;
  padding: 20px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;
