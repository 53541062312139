import { combineReducers } from "redux";
import homeReducer from './homeReducer';
import commonReducer from './commonReducer';
import cartReducer from './cartReducer';
import lessonReducer from './lessonReducer';
import authcartReducer from './authcartReducer';
import notificationReducer from './notificationReducer';

const AppReducers = combineReducers({
  home: homeReducer,
  common: commonReducer,
  cart: cartReducer,
  lessons: lessonReducer,
  authcart: authcartReducer,
  notification: notificationReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "RESET_STATE") {
    state = undefined;
  }

  return AppReducers(state, action);
};

export default rootReducer;
