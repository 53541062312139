import { Outlet } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import Footer from "./common/components/Footer";
import Header from "./common/components/Header/Header";
import { theme } from "./utils/theme";

const RootLayout = () => {
  return (
    <ThemeProvider theme={theme}>
      <Header />
      <Outlet />
      <Footer />
    </ThemeProvider>
  );
};

export default RootLayout;
