import React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import HeadingUnderline from "../../common/components/HeadingUnderline";

const Spacer = styled.div`
  height: 20px;
`;

const TermsConditions = () => (
  <Container>
    <Spacer />
    <HeadingUnderline title="Terms & Conditions" />
    <p>
      Welcome to Aditya Fitness. These Terms and Conditions govern your use of
      our Hormone Optimization Program and your interaction with our website. By
      purchasing and participating in this program, you agree to the following
      terms. Please read them carefully before proceeding with your purchase.
    </p>
    <h5>
      <strong>1.⁠ ⁠Service Overview</strong>
    </h5>
    <p>
      Aditya Fitness offers a one-time Hormone Optimization Program designed to
      help individuals reduce their exposure to Endocrine Disrupting Chemicals
      (EDCs) and optimize their hormonal health. This program provides
      educational resources, actionable steps, and guidance for implementing a
      lifestyle free of harmful EDCs.
    </p>
    <h5>
      <strong>2.⁠ ⁠User Responsibilities</strong>
    </h5>
    <p>
      By purchasing the program, you acknowledge that you are responsible for
      applying the information provided and that results may vary based on your
      unique health, lifestyle, and adherence to the program guidelines. It is
      your responsibility to consult with a healthcare professional before
      making any significant lifestyle changes, especially if you have existing
      medical conditions.
    </p>
    <h5>
      <strong>3.⁠ ⁠Payment Terms</strong>
    </h5>
    <p>
      The Hormone Optimization Program is available for a one-time payment of
      Rs. 999, inclusive of all taxes and fees. Payments must be made in full at
      the time of purchase through the payment methods provided on our website.
    </p>
    <h5>
      <strong>4.⁠ ⁠Cancellation and Refunds</strong>
    </h5>
    <p>
      All sales are final. Once the payment has been processed and access to the
      program has been granted, there will be no refunds or cancellations.
      Please ensure that you review the program details before making your
      purchase.
    </p>
    <h5>
      <strong>5.⁠ ⁠Privacy Policy</strong>
    </h5>
    <p>
      Our privacy policy, which can be found on a separate page, explains how we
      collect, use, and protect your personal information. By using this website
      and participating in the program, you agree to the terms outlined in our
      Privacy Policy.
    </p>
    <h5>
      <strong>6.⁠ ⁠Disclaimers and Limitations of Liability</strong>
    </h5>
    <p>
      Aditya Fitness provides educational content intended to improve your
      lifestyle and overall well-being. However, we make no guarantees regarding
      specific outcomes or health results. The program does not replace
      professional medical advice, and you should consult with your healthcare
      provider before making any changes to your diet, supplements, or fitness
      routine.
    </p>
    <p>
      Aditya Fitness and its representatives are not liable for any injuries,
      health complications, or other issues that may arise from participating in
      the program. By purchasing the program, you agree to assume full
      responsibility for any risks involved.
    </p>
    <h5>
      <strong>7.⁠ ⁠Intellectual Property</strong>
    </h5>
    <p>
      All content, materials, and resources provided as part of the Hormone
      Optimization Program are the intellectual property of Aditya Fitness. You
      may not distribute, reproduce, or share any of the program materials
      without express permission from Aditya Fitness.
    </p>
    <h5>
      <strong>8.⁠ ⁠Contact Information</strong>
    </h5>
    <p>
      For any questions or concerns regarding these Terms and Conditions, please
      contact us at: Email: support@adityafitness.in
    </p>

    <Spacer />
  </Container>
);

export default TermsConditions;
