import React, { useState } from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import { Col, Collapse, Row } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
// import { isUserLoggedIn } from "../../utils/helper";
// import BuyCourseButton from "../../components/BuyCourseButton";
import HeadingUnderline from "../../common/components/HeadingUnderline";

const { Panel } = Collapse;

const Spacer = styled.div`
  height: 20px;
`;

const FAQS = ({ landingPage }) => {
  const [_, changeKey] = useState("");
  const handleFaqChange = (key) => {
    changeKey(key);
  };

  return (
    <Container>
      {!landingPage && (
        <>
          <Spacer />
          <HeadingUnderline title="FAQs" />
        </>
      )}
      <Collapse
        className="site-collapse-custom-collapse"
        bordered={false}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
        onChange={handleFaqChange}
      >
        <Panel
          className="site-collapse-custom-panel"
          header="What are Endocrine Disrupting Chemicals (EDCs)?"
          key="51"
        >
          <p>
            ⁠Endocrine Disrupting Chemicals are substances found in everyday
            products that can interfere with the body's hormones, potentially
            causing adverse health effects.
          </p>
        </Panel>
        <Panel
          className="site-collapse-custom-panel"
          header="How do EDCs affect hormone levels?"
          key="52"
        >
          <p>
            ⁠EDCs can mimic or interfere with hormones in the body, leading to
            disruptions in hormone production, regulation, and function.
          </p>
        </Panel>
        <Panel
          className="site-collapse-custom-panel"
          header="What health problems can be associated with exposure to EDCs?"
          key="62"
        >
          <p>
            Exposure to EDCs has been linked to reproductive disorders, reduced
            fertility, developmental issues, metabolic disorders, and certain
            cancers.
          </p>
        </Panel>
        <Panel
          className="site-collapse-custom-panel"
          header="Will I need to take extra supplements as part of the hormone optimization program?"
          key="55"
        >
          <p>
            Our program primarily focuses on eliminating EDC exposure through
            lifestyle changes and dietary adjustments. While some individuals
            may benefit from supplements to support hormone balance, they are
            not mandatory for everyone. Any recommendations for supplements will
            be tailored to your specific needs.
          </p>
        </Panel>
        <Panel
          className="site-collapse-custom-panel"
          header="Are there extra costs for products I’ll need to buy during the program?"
          key="56"
        >
          <p>
            The core of our hormone optimization program does not require you to
            buy expensive products. However, we do suggest specific items to
            help reduce EDC exposure for people with extra disposable income.
          </p>
        </Panel>

        <Panel
          className="site-collapse-custom-panel"
          header="Is the damage caused by EDC exposure reversible?"
          key="58"
        >
          <p>
            In many cases, the negative effects of EDC exposure can be reduced
            or reversed by minimizing further exposure, optimizing hormone
            levels, and making targeted lifestyle changes. However, the extent
            of recovery depends on the individual, the duration of exposure, and
            the specific health issues involved.
          </p>
          <p>
            In certain cases, like damage caused by prenatal exposure, it is
            irreversible.
          </p>
        </Panel>

        <Panel
          className="site-collapse-custom-panel"
          header="How long will it take to see improvements and negate the side effects of EDC exposure?"
          key="60"
        >
          <p>
            The timeline for seeing improvements varies from person to person.
            Some may notice changes within a few weeks, while others may take
            several months, depending on the severity of the side effects and
            how consistently they follow the program.
          </p>
        </Panel>
      </Collapse>
      <Spacer />
    </Container>
  );
};

export default FAQS;
